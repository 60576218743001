import type { DataGridControl } from "o365-datagrid";
import type { DataItemModel, ItemModel, RecordSourceOptions } from "o365-dataobject";
import type { EventEmitter } from "o365-modules";
import type { NodeItem, ICalculatedField } from "./DataObject.NodeItem.ts";
import type { DataObject } from "o365-dataobject";
import { type NodeDataHierarchyConfigurationOptions } from './DataObject.Configurations.Hierarchy.ts';
import { type NodeDataGroupByConfigurationOptions } from './DataObject.Configurations.GroupBy.ts'

export type WorkerInitMessage = {
    type: 'init',
    workerId: string
}

export type WorkerMessageFunction = (value?: string, broadcaster?: URL) => Promise<string | undefined>;

export type WorkerFunctionBroadcastMessage = {
    operation: 'execute' | 'callback'
    name?: string,
    payload?: string,
    success?: boolean,
    meta: {
        broadcaster: string,
        uid: string
    }
}

export type ExecutableWorkerFunctions = Record<string, WorkerMessageFunction>;

/** Options for constructing group and tree structures  */
export type NodeDataStructureOptions = {
    /** Map of keys that should be expanded by default */
    expandedKeys?: Record<string, boolean>
    /** Auto expand nodes when filtering */
    autoExpandOnFilter?: boolean;
    /** Used to determine the staring level */
    startingLevel?: number;
}

export type NewNodeOptions<T extends ItemModel = ItemModel> = {
    item?: T | DataItemModel<T>
}

type NodeDataEvents<T extends ItemModel = ItemModel> = {
    'NodeAdded': (node: NodeItem<T>) => void,
    'ExpandedToNode': (node: NodeItem<T>) => void,
    'SetGroupBy': () => void,
    'AfterIndent': (pNode: NodeItem<T>) => void,
    'AfterOutdent': (pNode: NodeItem<T>) => void,
    'ConfigurationAdded': () => void,
    'ConfigurationRemoved': () => void,
    'LayoutApplied': () => void,
};

export interface INodeDataLevelConfiguration<T extends ItemModel = ItemModel> {
    ui: {
        title: string,
        type: string,
    }
    level: number;
    key: string;
    type: 'groupBy' | 'hierarchy';

    disabled: boolean;
    getStructure(pOptions: NodeDataStructureOptions, pNode?: NodeItem<T>): Promise<NodeItem<T>[]>;
    createNode(pOptions: NewNodeOptions<T>, pSkipReset?: boolean): NodeItem<T>;
    canCreateNodes(pNode: NodeItem<T>): boolean;
    updateNodeParent(pNode: NodeItem<T>, pParent?: NodeItem<T>, pParentId?: string | number): void;
    getConfigurationForLevel: (pLevel: number) => INodeDataLevelConfiguration<T> | undefined;
    /**
     * Get required configuration fields
     * @v2
     */
    getRequiredFields: () => string[];
    /**
     * @param pData - already filtered data
     * @v2
     */
    getNodes: (pData: T[], pOtions: NodeDataStructureOptions) => {
        root: NodeItem<T>[],
        boundry: [NodeItem<T>, T[]][],
        depth: number
    };
    /**
     * Get placeholder node
     * @v2
     */
    getPlaceholder: (pNode: NodeItem<T>, pOptions: NodeDataStructureOptions) => NodeItem<T>;
    /** When true will expand added nodes by default */
    expandByDefault: boolean;
}

export interface INodeData<T extends ItemModel = ItemModel> {
    /** When true will auto expand all nodes after filtering */
    autoExpandOnFilter: boolean;
    /**
     * When true will load all of the necessary fields for all of the rows with the current whereClause/filterString. The node
     * structure will then be constructed on client. This mode is not suited for very large datasets and is geared more towards <20k rows.
     */
    loadFullStructure: boolean;
    /** Current expanded level used by other controls */
    currentLevel: number;
    /** Deepest level in the structure */
    deepestLevel: number;
    /** Events emitter utilized by other controls for reacting to node data changes */
    events: EventEmitter<NodeDataEvents<T>>;
    /** When true setting isSelected will not update detail nodes isSelected values */
    disableDetailsMultiSelect: boolean;
    /** When true will not restore expanded states from local store */
    disableLocalStore: boolean;
    /** When set to true will disable auto new record on empty roots */
    disableAutoNewRecord: boolean;

    enableLayouts: boolean;

    /**
     * View that will be used for grouping system properties. 
     * This view needs to have properties values table joined in onto the main view and
     * select [PropertyName] with [Value] AS 'PropertyName' in addition to the same fields as the main view.
     */
    withPropertiesView?: string;
    withPropertiesDefinitionProc?: string;

    /**
     * Indicates that the NodeItem's are allowed to create new items
     */
    canCreateNodes: boolean;

    /** Date at which display data was last updated. Used by watchers */
    updated: Date;

    /** Indicates if the node data overrides are currently enabled */
    enabled: boolean;

    /** Local storage for storing/retrieving which rows are epxanded */
    localStorageKey: string;

    /** Root array of NodeItems */
    root: NodeItem<T>[];

    /** Get the current index node item */
    current?: NodeItem<T>;

    /** Node levels configurations */
    configurations: INodeDataLevelConfiguration[];

    /** Indicates that there is at least one configuration that is enabled */
    isActive: boolean;

    /** Computed fields that are calculated on the clientside in the worker. */
    calculatedFields: ICalculatedField<T>[];


    /** Should not be called outside of DataObject */
    initialize: () => void;

    /** Enable NodeData overrides on the DataObject */
    enable: () => void;

    /** Disable NodeData overrides on the DataObject */
    disable: () => void;

    /** Push new level of structure configuration */
    addConfiguration: (pOptions: NodeDataHierarchyConfigurationOptions<T> | NodeDataGroupByConfigurationOptions<T>, pLevel?: number) => void;

    /** Remove a configuration level */
    removeConfiguration: (pLevel: number) => void;

    /** Remove all configuration levels */
    removeAllConfigurations: () => void;

    /** Change configuration level */
    moveConfiguration: (pFromLevel: number, pToLevel: number) => void;

    /** TODO: Remove and encourage the usage of dataObject.load */
    init: () => Promise<void>;

    /** Create new item and push it to root */
    createNew: (pItem: any) => NodeItem<T>;

    /** Expand all nodes that have details in the entire structure */
    expandAll: () => void;

    /** Expand all nodes that have details and have currently expanded parents */
    expandAllVisible: () => void;

    /** Collapse all nodes that have details in the entire structure */
    collapseAll: () => void;

    /** Collapse all nodes that have details and have currently expanded parents */
    collapseAllVisible: () => void;

    /** Expand/collapse rows to a given level */
    expandToLevel: (pLevel: number) => void;

    /** Flatten out expanded nodes from root into an array */
    getFlatStructure: () => NodeItem<T>[];

    /** Update the DataObject row count with the loaded structure items count */
    updateRowCount: () => void;

    addNode: (pItem: DataItemModel<T>, pOptions?: {
        /** If provided will push to this node's details. When none is provided will push to root */
        parentNode?: NodeItem<T>,
        /** If provided will push right after this node. When none provided will push to the end of parent/root */
        siblingNode?: NodeItem<T>
    }) => NodeItem<T> | undefined;

    /** Update the displayed data with flattened node items */
    update: () => void;

    /**
     * Get data required for constructing the node structure based on current configuration
     * Only used when loadFullStructure is enabled
     */
    getData: () => Promise<Partial<T>[]>;

    /**
     * Traverse find a node with a predicate
     * Keep in mind that the non visible nodes might be not loaded yet and will have partial values
     */
    findNode: (pPredicate: (pNode: NodeItem<T>) => boolean) => (NodeItem<T> | undefined);

    /**
     * Find NodeItem in the loaded structure with the provided PrimKey
     */
    findNodeByPrimKey: (pPrimKey: string) => (NodeItem<T> | undefined);

    /**
     * Find NodeItem in the loaded structure with the provided PrimKey
     */
    findNodeByFetchKey: (pKey: string) => (NodeItem<T> | undefined);


    enableUrl: () => void;

    // --- DataObject overrides ---
    /**
     * DataObject.load override
     * @ignore
     */
    load(...[pOptions]: Parameters<DataObject<T>['load']>): ReturnType<DataObject<T>['load']>
    /**
     * DataObject.recordSource.retrieve override
     * @ignore
     */
    retrieve(pOptions: Partial<RecordSourceOptions>): Promise<Partial<T>[]>;

    // ----------------------------

    /** Set calculated fields options for NodeItem instances of this DataObject */
    setCalculatedFields(pFields: ICalculatedField<T>[]): void;

    /**
     * Helper method for programmatically setting group by on a data object
     * This method will add group by configuraionts for the provided fields
     */
    setGroupBy(pGroupBy: (Field<T> | Field<T>[])[], pOptions?: {
        dataGridControl?: DataGridControl
    }): void;

    clearStoredState(): void;

    loadAllRows(): Promise<NodeItem<T>[]>;

    /** Reassign paretns to get proxies working */
    reassignParents(): void;
}

type Field<T> = keyof T & string;